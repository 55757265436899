import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import BottomBarButton from '../BottomBar/BottomBarButton'

import {
  rotate,
  modeSelector,
  MODE,
  stepCountSelector,
  stepSelector,
  loadingSelector
} from '../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RotatorRotateLeftButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const currentStep = useSelector(stepSelector)
  const stepCount = useSelector(stepCountSelector)
  const loading = useSelector(loadingSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(rotate(1))
  }, [dispatch])

  return (
    <BottomBarButton
      {...props}
      tooltip="Siguiente"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={
        loading ||
        !(mode === MODE.BUTTONS || mode === MODE.PAN) ||
        currentStep === stepCount - 1
      }
    >
      <SkipNextIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default RotatorRotateLeftButton
