export const vector = (x = 0, y = 0) => ({ x: x, y: y })
export const vectorFromPoints = (startPoint, endPoint) => {
  return { x: endPoint.x - startPoint.x, y: endPoint.y - startPoint.y }
}
export const addVectors = (v0, v1) => ({ x: v0.x + v1.x, y: v0.y + v1.y })
export const scaleVector = (v, scale) => {
  return { x: v.x * scale, y: v.y * scale }
}
export const rotateVector = (v, rad) => {
  const cos = Math.cos(rad)
  const sin = Math.sin(rad)
  return { x: v.x * cos - v.y * sin, y: v.x * sin + v.y * cos }
}

export const radToDegree = (rad) => {
  return rad * (180 / Math.PI)
}

export const degreeToRad = (degree) => {
  return degree * (Math.PI / 180)
}

export const angleBetweenVectors = (v0, v1) => {
  return Math.atan2(v0.x * v1.y - v0.y * v1.x, v0.x * v1.x + v0.y * v1.y)
}

export const rangeValue = (value, min, max) =>
  Math.min(Math.max(value, min), max)

export const boxCenter = (top, left, width, height) => {
  return vector(left + width / 2, top + height / 2)
}

export const getCentroid = (points) => {
  if (points.length) {
    let centroid = points.reduce(
      (accumulator, point) => {
        accumulator[0] += point[0]
        accumulator[1] += point[1]
        return accumulator
      },
      [0, 0]
    )
    return [centroid[0] / points.length, centroid[1] / points.length]
  }
  return [0, 0]
}

export const getBoundingBox = (points) => {
  if (points.length === 0) {
    return [0, 0, 0, 0]
  }
  let minX = points[0][0]
  let maxX = points[0][0]
  let minY = points[0][1]
  let maxY = points[0][1]
  for (let point of points) {
    minX = Math.min(minX, point[0])
    maxX = Math.max(maxX, point[0])
    minY = Math.min(minY, point[1])
    maxY = Math.max(maxY, point[1])
  }

  return [minX, minY, maxX, maxY]
}

export const getBoundingboxOfPolygons = (polygons) => {
  const points = polygons.reduce((acc, polygon) => {
    return [...acc, ...polygon]
  }, [])
  return getBoundingBox(points)
}

export const isPointInsideBounds = (x, y, bounds) => {
  if (!bounds) {
    return false
  }
  const xOffset = 20 //Esta por el popper, para que no se salga
  return (
    x > bounds.x + xOffset &&
    y > bounds.y &&
    x < bounds.x + bounds.width - xOffset &&
    y < bounds.y + bounds.height
  )
}
