import { useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import BottomBarButton from '../BottomBar/BottomBarButton'

import { rotationSelector, setRotation } from '../../modules/reducerPDFViewer'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PDFRotateLeftButton = (props) => {
  // const classes = useStyles()
  const rotation = useSelector(rotationSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setRotation(rotation - 90))
  }, [dispatch, rotation])

  return (
    <BottomBarButton
      {...props}
      tooltip="Rotar sentido antihorario"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={false}
    >
      <RotateLeftIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default PDFRotateLeftButton
