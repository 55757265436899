import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import ZoomInIcon from '@material-ui/icons/ZoomIn'
import BottomBarButton from '../BottomBar/BottomBarButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {
  currentPageIdxSelector,
  setPageIdx
} from '../../modules/reducerPDFViewer'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PDFPrevButton = (props) => {
  // const classes = useStyles()
  const pageIdx = useSelector(currentPageIdxSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setPageIdx(pageIdx - 1))
  }, [dispatch, pageIdx])

  return (
    <BottomBarButton
      {...props}
      tooltip="Anterior"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={pageIdx <= 0}
    >
      <ArrowUpwardIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default PDFPrevButton
