import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import ZoomInIcon from '@material-ui/icons/ZoomIn'
import BottomBarButton from '../BottomBar/BottomBarButton'
import AddIcon from '@material-ui/icons/Add'
import {
  ZOOM_FACTOR,
  modeSelector,
  MODE,
  zoomAtGlobalPoint,
  getGlobalCenter
} from '../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RotatorZoomInButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const canvasCenter = useSelector(getGlobalCenter)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(zoomAtGlobalPoint(1 + ZOOM_FACTOR, canvasCenter))
  }, [dispatch, canvasCenter])

  return (
    <BottomBarButton
      {...props}
      tooltip="Acercar"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={mode === MODE.PANNING || mode === MODE.ROT_MOUSE}
    >
      <AddIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default RotatorZoomInButton
