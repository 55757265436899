import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import PDFNextButton from './PDFNextButton'
import PDFPrevButton from './PDFPrevButton'
import PDFPageInput from './PDFPageInput'
import PDFRotateRightButton from './PDFRotateRightButton'
import PDFRotateLeftButton from './PDFRotateLeftButton'
import PDFZoomOutButton from './PDFZoomOutButton'
import PDFZoomInButton from './PDFZoomInButton'
import PDFZoomSelect from './PDFZoomSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '64px',
    backgroundColor: theme.palette.pdf.background,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  buttonGroup: {
    marginRight: '5px',
    marginLeft: '5px'
  }
}))

const PDFBar = ({ small }) => {
  const classes = useStyles()
  // const loading = useSelector(loadingSelector)

  return (
    <div className={classes.root}>
      <ButtonGroup
        className={classes.buttonGroup}
        disableRipple
        disableFocusRipple
      >
        <PDFPrevButton />
        {small ? null : <PDFPageInput />}
        <PDFNextButton />
      </ButtonGroup>

      <ButtonGroup
        className={classes.buttonGroup}
        disableRipple
        disableFocusRipple
      >
        <PDFRotateRightButton />
        <PDFRotateLeftButton />
      </ButtonGroup>

      <ButtonGroup
        className={classes.buttonGroup}
        disableRipple
        disableFocusRipple
      >
        <PDFZoomOutButton />
        {small ? null : <PDFZoomSelect />}
        <PDFZoomInButton />
      </ButtonGroup>
    </div>
  )
}
export default PDFBar
