// import { makeStyles } from '@material-ui/core/styles'
import BottomBarButton from './BottomBarButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const ShowBarButton = ({ barVisible, onClick, props }) => {
  // const classes = useStyles()

  return (
    <BottomBarButton
      color="primary"
      tooltip={'Mostrar'}
      tooltipEnabled={!barVisible}
      onClick={onClick}
      disabled={false}
    >
      <ArrowUpwardIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default ShowBarButton
