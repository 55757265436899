import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import ZoomInIcon from '@material-ui/icons/ZoomIn'
import BottomBarButton from '../BottomBar/BottomBarButton'
import RemoveIcon from '@material-ui/icons/Remove'
import {
  scaleSelector,
  ZOOM_FACTOR,
  SCALE_RANGE,
  setScale
} from '../../modules/reducerPDFViewer'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PDFZoomOutButton = (props) => {
  // const classes = useStyles()
  const scale = useSelector(scaleSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setScale(scale - ZOOM_FACTOR))
  }, [dispatch, scale])

  return (
    <BottomBarButton
      {...props}
      tooltip="Aumentar"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={scale >= SCALE_RANGE.max}
    >
      <RemoveIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default PDFZoomOutButton
