import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import ZoomInIcon from '@material-ui/icons/ZoomIn'
import BottomBarButton from '../BottomBar/BottomBarButton'
import AddIcon from '@material-ui/icons/Add'
import {
  scaleSelector,
  ZOOM_FACTOR,
  SCALE_RANGE,
  setScale
} from '../../modules/reducerPDFViewer'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PDFZoomInButton = (props) => {
  // const classes = useStyles()
  const scale = useSelector(scaleSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setScale(scale + ZOOM_FACTOR))
  }, [dispatch, scale])

  return (
    <BottomBarButton
      {...props}
      tooltip="Reducir"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={scale <= SCALE_RANGE.min}
    >
      <AddIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default PDFZoomInButton
