// import { makeStyles } from '@material-ui/core/styles'
import BottomBarButton from './BottomBarButton'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const HideBarButton = ({ barVisible, disabled, props, onClick }) => {
  // const classes = useStyles()

  return (
    <BottomBarButton
      {...props}
      color="primary"
      tooltip={'Ocultar'}
      tooltipEnabled={barVisible}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowDownwardIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default HideBarButton
