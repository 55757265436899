import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import RotateRightIcon from '@material-ui/icons/RotateRight'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import BottomBarButton from '../BottomBar/BottomBarButton'

import {
  rotate,
  modeSelector,
  MODE,
  stepSelector,
  loadingSelector
} from '../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RotatorRotateRightButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const currentStep = useSelector(stepSelector)
  const loading = useSelector(loadingSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(rotate(-1))
  }, [dispatch])

  return (
    <BottomBarButton
      {...props}
      tooltip="Anterior"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={
        loading ||
        !(mode === MODE.BUTTONS || mode === MODE.PAN) ||
        currentStep === 0
      }
    >
      <SkipPreviousIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default RotatorRotateRightButton
