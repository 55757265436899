import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import BottomBarButton from '../BottomBar/BottomBarButton'

import { modeSelector, MODE, resetView } from '../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RotatorHomeButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(resetView())
  }, [dispatch])

  return (
    <BottomBarButton
      {...props}
      tooltip="Home"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={
        mode === MODE.ROT_AUTOMATIC ||
        mode === MODE.ROT_MOUSE ||
        mode === MODE.PANNING
      }
    >
      <HomeIcon fontSize="inherit" />
    </BottomBarButton>
  )
}
export default RotatorHomeButton
