/// import { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
// import { useSelector } from 'react-redux'

import BottomBar from '../BottomBar/BottomBar'
import RotatorZoomOutButton from './RotatorZoomOutButton'
import RotatorZoomInButton from './RotatorZoomInButton'
import RotatorRotateRightButton from './RotatorRotateRightButton'
import RotatorPlayButton from './RotatorPlayButton'
import RotatorRotateLeftButton from './RotatorRotateLeftButton'
import RotatorHomeButton from './RotatorHomeButton'
import RotatorPanButton from './RotatorPanButton'
// import ShowBarButton from './ShowBarButton'
// import { loadingSelector } from '../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonGroup: {
    marginRight: '5px',
    marginLeft: '5px'
  }
}))

const RotatorBar = ({ small }) => {
  const classes = useStyles()
  // const loading = useSelector(loadingSelector)

  return (
    <BottomBar>
      <ButtonGroup className={classes.buttonGroup}>
        <RotatorHomeButton />
        <RotatorPanButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup}>
        {small ? null : <RotatorRotateRightButton />}
        <RotatorPlayButton />
        {small ? null : <RotatorRotateLeftButton />}
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup}>
        <RotatorZoomOutButton />
        <RotatorZoomInButton />
      </ButtonGroup>
    </BottomBar>
  )
}
export default RotatorBar
