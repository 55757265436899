import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import { findById } from '../utils/utils'

export const VIDEO_SET_VIDEO = 'VIDEO_SET_VIDEO'

const initialState = {
  videos: [
    {
      id: 'v0',
      name: 'Presentación',
      src: 'Videos/General/Lomas del  higueron.mp4'
    }
  ],
  currentVideo: ''
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case VIDEO_SET_VIDEO:
      return { ...state, currentVideo: action.id }
    default:
      return state
  }
}
export default changeState

export const setVideo = (id) => ({
  type: VIDEO_SET_VIDEO,
  id
})

const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/x-m4v',
  'video/quicktime',
  'video/x-ms-asf',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'application/x-troff-msvideo',
  'video/avi',
  'application/x-troff-msvideo',
  'video/msvideo',
  'video/mpeg',
  'video/ogg',
  'video/3gpp',
  'video/3gpp2'
]

export const currentVideoSelector = (state) => state.videos.currentVideo
// export const videosSelector = (state) => state.videos.videos
export const videosSelector = createSelector([dataSelector], (data) => {
  const media = findById(data, DATA_TYPE.MEDIA)
  return media
    ? media.data.reduce((acc, video) => {
        if (VIDEO_MIME_TYPES.indexOf(video.mime_type) >= 0) {
          acc.push({
            id: MEDIA_TYPE.VIDEO + video.id,
            name: video.title.rendered,
            src: video.source_url
          })
        }
        return acc
      }, [])
    : []
})

export const getCurrentVideo = createSelector(
  [videosSelector, currentVideoSelector],
  (videos, currentVideo) => {
    return findById(videos, currentVideo)
  }
)

export const getVideo = () =>
  createSelector([videosSelector, (_, id) => id], (videos, id) => {
    return findById(videos, id)
  })

export const getVideoBySrc = () =>
  createSelector([videosSelector, (_, src) => src], (videos, src) => {
    return videos.find((v) => v.src === src)
  })
