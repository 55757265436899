import { createSelector } from 'reselect'

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const texts = {
  Anterior: { es: 'Anterior', en: 'Back' },
  Siguiente: { es: 'Siguiente', en: 'Next' },
  'Solicitud de preingreso': {
    es: 'Solicitud de preingreso',
    en: 'Solicitud de preingreso'
  },
  'Breve descripción de la situación familiar. ¿Qué problema existe?': {
    es: 'Breve descripción de la situación familiar. ¿Qué problema existe?',
    en: 'Breve descripción de la situación familiar. ¿Qué problema existe?'
  },
  'Introduce texto': { es: 'Introduce texto', en: 'Introduce texto' },
  'Diagnóstico mental': { es: 'Diagnóstico mental', en: 'Diagnóstico mental' },
  'Diagnóstico físico': { es: 'Diagnóstico físico', en: 'Diagnóstico físico' },
  'Documentación del Paciente a aportar': {
    es: 'Documentación del Paciente a aportar',
    en: 'Documentación del Paciente a aportar'
  },
  'Documento de Identidad (NIF)': {
    es: 'Documento de Identidad (NIF)',
    en: 'Documento de Identidad (NIF)'
  },
  'Informe Médico': { es: 'Informe Médico', en: 'Informe Médico' },
  'Seguro Deceso': { es: 'Seguro Deceso', en: 'Seguro Deceso' },
  'Nº Cuenta': { es: 'Nº Cuenta', en: 'Nº Cuenta' },
  Certificados: { es: 'Certificados', en: 'Certificados' },
  'Libro de Familia': { es: 'Libro de Familia', en: 'Libro de Familia' },
  'El preingreso se hará efectivo una vez se cumplimente toda la documentación solicitada': {
    es:
      'El preingreso se hará efectivo una vez se cumplimente toda la documentación solicitada',
    en:
      'El preingreso se hará efectivo una vez se cumplimente toda la documentación solicitada'
  },
  'Enviar Copia': {
    es: 'Enviar Copia',
    en: 'Enviar Copia'
  },
  'Texto pregunta 0': {
    es: 'Texto pregunta 0',
    en: 'Texto pregunta 0'
  },
  'Texto pregunta 1': {
    es: 'Texto pregunta 1',
    en: 'Texto pregunta 1'
  },
  'Texto pregunta 2': {
    es: 'Texto pregunta 2',
    en: 'Texto pregunta 2'
  },
  'Texto pregunta 3': {
    es: 'Texto pregunta 3',
    en: 'Texto pregunta 3'
  },
  'Texto pregunta 4': {
    es: 'Texto pregunta 4',
    en: 'Texto pregunta 4'
  },
  'Texto pregunta 5': {
    es: 'Texto pregunta 5',
    en: 'Texto pregunta 5'
  },
  'Texto pregunta 6': {
    es: 'Texto pregunta 6',
    en: 'Texto pregunta 6'
  },
  'Texto pregunta 7': {
    es: 'Texto pregunta 7',
    en: 'Texto pregunta 7'
  },
  'Texto pregunta 8': {
    es: 'Texto pregunta 8',
    en: 'Texto pregunta 8'
  },
  'Texto pregunta 9': {
    es: 'Texto pregunta 9',
    en: 'Texto pregunta 9'
  },
  'Texto pregunta 10': {
    es: 'Texto pregunta 10',
    en: 'Texto pregunta 10'
  },
  'Texto pregunta 11': {
    es: 'Texto pregunta 11',
    en: 'Texto pregunta 11'
  },
  'Texto pregunta 12': {
    es: 'Texto pregunta 12',
    en: 'Texto pregunta 12'
  },
  'Texto pregunta 13': {
    es: 'Texto pregunta 13',
    en: 'Texto pregunta 13'
  },
  'Texto pregunta 14': {
    es: 'Texto pregunta 14',
    en: 'Texto pregunta 14'
  },
  Cancelar: { es: 'Cancelar', en: 'Cancelar' },
  Enviar: { es: 'Enviar', en: 'Enviar' },
  Email: { es: 'Email', en: 'Email' },
  'Completar Preingreso': {
    es: 'Completar Preingreso',
    en: 'Completar Preingreso'
  },
  'Enviar a Paciente': {
    es: 'Enviar a Paciente',
    en: 'Enviar a Paciente'
  },
  'Copia de la solicitud de preingreso y Documentación a aportar.': {
    es: 'Copia de la solicitud de preingreso y Documentación a aportar.',
    en: 'Copia de la solicitud de preingreso y Documentación a aportar.'
  }
}

const initialState = {
  languages: [
    { id: 'es', text: 'Español' },
    { id: 'en', text: 'English' }
  ],
  language: 'es'
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language !== '' ? action.language : 'es'
      }
    default:
      return state
  }
}
export default changeState

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  language
})

export const languageSelector = (state) => state.translate.language
export const languagesSelector = (state) => state.translate.languages

export const getLanguage = createSelector(
  [languagesSelector],
  (languages) => (id) => {
    return languages.find((lan) => lan.id === id)
  }
)

export const getCurrentLanguage = createSelector(
  [getLanguage, languageSelector],
  (gLanguage, id) => {
    return gLanguage(id)
  }
)

export const getText = createSelector(
  [languageSelector],
  (language) => (text) => {
    const textObj = texts[text]
    // console.log(text, textObj ? textObj[language] : 'No esta')
    return textObj ? textObj[language] : ''
  }
)
