import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './modules'

// import { from } from 'rxjs'

// import { setAxiosConfig } from 'redux-json-api'

const initialState = {}
const enhancers = []
const middleware = [thunk]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

// export const store$ = from(store)

// store.dispatch(
//   setAxiosConfig({
//     baseURL: 'https://havalook.proyectosavanza.com/odon/administracion/jsonapi'
//   })
// )

export default store
